import { Injectable } from '@angular/core'
import { Meta } from '@angular/platform-browser'

@Injectable({
    providedIn: 'root',
})
export class MetaService {
    constructor(private meta: Meta) {}

    /**
     * This will method set the viewport width to 1024
     */
    setDefaultViewport(viewportWidth?: number) {
        this.meta.updateTag({ name: 'viewport', content: `width=${viewportWidth ?? 1024}` })
    }

    /**
     * This method is used if you add page responsive viewport
     */
    setMobileViewport() {
        this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' })
    }
}
