import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AnalyticsService } from './analytics.service'
import { environment } from '@env/environment'

@NgModule({
    imports: [CommonModule],
    providers: [AnalyticsService],
})
export class AnalyticsModule {
    constructor(private analyticsService: AnalyticsService) {
        this.analyticsService.initialize(environment.analytics.GA_TRACKING_ID)
    }
}
