import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { environment } from 'env'
import { AuthGuard } from './core/guards/auth.guard'
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component'

const featureList: Record<string, boolean> = environment.featureList

const featureRoutes: Routes = []

const appRoutes: Routes = []

let defaultRedirect = ''

if (featureList.landingPage) {
    featureRoutes.push({
        path: 'landing-page',
        data: { responsive: true, title: 'LANDING' },
        loadChildren: () => import('./modules/landing-page/landing-page.module').then((m) => m.LandingPageModule),
    })

    defaultRedirect = 'landing-page'
}

if (featureList.dashboard) {
    appRoutes.push({
        path: 'dashboard',
        data: { meta: { title: 'DASHBOARD' } },
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    })

    defaultRedirect = 'dashboard'
}

if (featureList.login) {
    appRoutes.push({
        path: 'login',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    })
}

if (featureList.app) {
    featureRoutes.push({
        path: '',
        loadChildren: () => import('./modules/app/app.module').then((m) => m.AppModule),
    })
}

const routes: Routes = [
    {
        path: '',
        redirectTo: defaultRedirect,
        pathMatch: 'full',
    },
    ...featureRoutes,
    {
        path: 'legal',
        data: { responsive: true, title: 'LEGAL', meta: { title: 'LEGAL' } },
        loadChildren: () => import('./modules/legal-page/legal-page.module').then((m) => m.LegalPageModule),
    },
    {
        path: '404',
        data: { title: '404' },
        component: PageNotFoundComponent,
    },
    {
        path: '**',
        redirectTo: '/404',
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            initialNavigation: 'enabledBlocking',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}

export const APP_CHILD_ROUTES = appRoutes
