import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { ActivatedRoute, Router } from '@angular/router'
import { StorageService } from '@app/core/services/storage/storage.service'
import { BehaviorSubject } from 'rxjs'
import LANGUAGE_LIST, { APP_LANGUAGE_LIST } from './language'

@Injectable({
    providedIn: 'root',
})
export class AppTranslateService {
    #FALLBACK_LOCALE = 'en-US'
    #LOCAL_STORE_NAME = 'idb-locale'
    #DEFAULT_LOCALE = this.getBrowserLang()
    locale$ = new BehaviorSubject<string | null>(null)
    // this is internal state managed for pdf document download, do not use this anywhere
    pdfDocLocale$ = new BehaviorSubject<string>(this.getLocale())
    browserLocale$ = new BehaviorSubject<string>(this.getBrowserLang())

    static readonly language_list = LANGUAGE_LIST
    static readonly app_language_list = APP_LANGUAGE_LIST

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private localStore: StorageService,
        private translate: TranslateService
    ) {}

    /**
     * This function is called to initialize the default/fallback transalation and create route change event listner
     */
    // istanbul ignore next
    init() {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.locale) {
                this.pdfDocLocale$.next(params.locale)
                // next level option to avoid persistent store of localization key
                if (params.viewOnly) {
                    this.translate.use(params.locale)
                } else {
                    this.use(params.locale)
                }
            } else {
                this.pdfDocLocale$.next(this.getLocale())
            }
        })

        this.translate.use(this.getLocale())
    }

    get locale() {
        return this.locale$.value || this.#DEFAULT_LOCALE
    }

    /**
     * This function is used to get the server locale text
     * @returns the server locale text
     */
    serverLocale(): string {
        return this.findServerLocale(this.getLocale()) as string
    }

    /**
     * This function is used to change the locale value
     * @param locale - is the new language code
     */
    use(locale: string): void {
        this.pdfDocLocale$.next(locale)

        this.locale$.next(locale)
        this.translate.use(locale)
        this.localStore.add(this.#LOCAL_STORE_NAME, locale)
    }

    /**
     * This function is used to read the active locale value from the store
     */
    getLocale(): string {
        const value = this.localStore.get(this.#LOCAL_STORE_NAME) as string
        return value ?? this.#DEFAULT_LOCALE
    }

    /**
     *
     * @returns this will return the browser default language
     */
    getBrowserLang() {
        const availableLangs = this.getLangs()
        const browserLang = this.translate.getBrowserCultureLang() as string
        return availableLangs.includes(browserLang) ? browserLang : this.#FALLBACK_LOCALE
    }

    /**
     *
     * @returns Returns an array of currently available langs
     */
    getLangs() {
        return AppTranslateService.language_list.map((d) => d.id)
    }

    /**
     *
     * @returns Returns a proper url for different language, given current url and language
     */
    getLangUrl(u: string, locale: string): string {
        const url = new URL(u)
        url.searchParams.set('locale', locale)
        return url.toString()
    }

    /**
     *
     * @param locale the browser translate code
     * @returns return the server translate code
     */
    findServerLocale(locale: string) {
        return AppTranslateService.language_list.find((d) => d.id === locale)?.serverLocale
    }

    /**
     *
     * @param locale the server translate code
     * @returns return the browser translate code
     */
    findBrowserLocale(locale: string) {
        return AppTranslateService.language_list.find((d) => d.serverLocale === locale)?.id
    }

    /**
     * This function is used to save transalate code in BehaviorSubject
     * @param locale is the language code
     */
    changeUserLocale(locale: string) {
        this.locale$.next(locale)
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { locale },
            queryParamsHandling: 'merge',
        })
    }
}
