import { Injectable, Inject } from '@angular/core'
import { DOCUMENT } from '@angular/common'
import { Router } from '@angular/router'

export declare type LinkDefinition = {
    charset?: string
    crossorigin?: string
    href?: string
    hreflang?: string
    media?: string
    rel?: string
    rev?: string
    sizes?: string
    target?: string
    type?: string
} & {
    [prop: string]: string
}

@Injectable({
    providedIn: 'root',
})
export class LinkService {
    constructor(@Inject(DOCUMENT) private readonly document: Document, private readonly router: Router) {}

    /**
     * Create or update a link tag
     * @param  {LinkDefinition} tag
     * @param  {string} selectorAttribute - key from LinkDefinition which should be used to decide if element should be updated or created
     */
    public updateTag(tag: LinkDefinition, selectorAttribute?: keyof LinkDefinition): void {
        const selector = this._parseSelector(tag, selectorAttribute)
        const linkElement =
            <HTMLLinkElement>this.document.head.querySelector(selector) ||
            this.document.head.appendChild(this.document.createElement('link'))

        if (linkElement) {
            Object.keys(tag).forEach((prop: string) => {
                // @ts-ignore
                linkElement[prop] = tag[prop]
            })
        }
    }

    /**
     * Remove a link tag from DOM
     * @param  tag
     */
    public removeTag(tag: LinkDefinition): void {
        const selector = this._parseSelector(tag)
        const linkElement = <HTMLLinkElement>this.document.head.querySelector(selector)

        if (linkElement) {
            this.document.head.removeChild(linkElement)
        }
    }

    /**
     * Get link tag
     * @param  tag
     * @return {HTMLLinkElement}
     */
    public getTag(tag: LinkDefinition): HTMLLinkElement | null {
        const selector = this._parseSelector(tag)

        return this.document.head.querySelector(selector)
    }

    /**
     * Get all link tags
     * @return {NodeListOf<HTMLLinkElement>}
     */
    public getTags(): NodeListOf<HTMLLinkElement> {
        return this.document.head.querySelectorAll('link')
    }

    /**
     * Parse tag to create a selector
     * @param  tag
     * @param  {string} selectorAttribute - key from LinkDefinition which should be used to decide if element should be updated or created
     * @return {string} selector to use in querySelector
     */
    private _parseSelector(tag: LinkDefinition, selector?: keyof LinkDefinition): string {
        if (selector) {
            return `link[${selector}="${tag[selector]}"]`
        }
        const attr: string = tag.rel ? 'rel' : 'hreflang'
        return `link[${attr}="${tag[attr]}"]`
    }
}
