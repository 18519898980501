import { Injectable, Injector } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { map, filter, switchMap } from 'rxjs/operators'
import { from, Observable } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private router: Router, private injector: Injector) {}

    canActivate(): Observable<boolean | UrlTree> {
        return from(import('../services/auth/auth.service')).pipe(
            switchMap(({ AuthService }) => {
                const auth = this.injector.get(AuthService)
                return auth.logged.pipe(
                    filter((x) => x !== null),
                    map((logged) => {
                        if (!logged) {
                            return this.router.createUrlTree(['login'])
                        }
                        return true
                    })
                )
            })
        )
    }
}
