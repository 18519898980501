import { HttpClient } from '@angular/common/http'
import { TranslateLoader } from '@ngx-translate/core'
import { catchError, map, Observable, of } from 'rxjs'
import LANGUAGE_LIST from './language'

export class AppTranslateLoaderService implements TranslateLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        const path = this.getTranslationPath(lang)
        return this.http.get(path).pipe(
            map((res: Object) => this.transformObject(res)),
            catchError(() => {
                // Handle the error
                return of({})
            })
        )
    }

    getTranslationPath(lang: string): string {
        // Modify this function to dynamically create the path based on the language code
        const selection = LANGUAGE_LIST.find((x) => x.id === lang)
        const langCode = selection?.languageCode ?? 'en'
        return `/assets/i18n/${langCode}.json`
    }

    private transformObject<T extends Record<string, any>>(inputObj: T): T {
        const resultObj = {} as T

        for (const prop in inputObj) {
            if (inputObj.hasOwnProperty(prop)) {
                if (typeof inputObj[prop] === 'object' && inputObj[prop] !== null) {
                    resultObj[prop] = this.transformObject(inputObj[prop])
                } else if (typeof inputObj[prop] === 'string' && inputObj[prop] === '') {
                    // Skip empty strings
                } else {
                    resultObj[prop] = inputObj[prop]
                }
            }
        }

        return resultObj
    }
}
