import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ErrorPageComponent } from './error-page.component'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
    declarations: [ErrorPageComponent],
    exports: [ErrorPageComponent],
    imports: [CommonModule, TranslateModule],
})
export class ErrorPageModule {}
