const LANGUAGE_LIST = [
    {
        id: 'de-AT',
        serverLocale: 'de-AT',
        name: 'LANGUAGE.AUSTRIA',
        imgSrc: 'assets/images/country/austria.svg',
    },
    {
        id: 'fr-BE',
        serverLocale: 'fr-BE',
        name: 'LANGUAGE.BELGIUM',
        imgSrc: 'assets/images/country/belgium.svg',
    },
    {
        id: 'da',
        serverLocale: 'da-DK',
        name: 'LANGUAGE.DENMARK',
        imgSrc: 'assets/images/country/denmark.svg',
    },
    {
        id: 'fi',
        serverLocale: 'fi-FI',
        name: 'LANGUAGE.FINLAND',
        imgSrc: 'assets/images/country/finland.svg',
    },
    {
        id: 'fr-FR',
        serverLocale: 'fr-FR',
        name: 'LANGUAGE.FRANCE',
        imgSrc: 'assets/images/country/france.svg',
    },
    {
        id: 'de-DE',
        languageCode: 'de',
        serverLocale: 'de-DE',
        name: 'LANGUAGE.GERMANY',
        imgSrc: 'assets/images/country/germany.svg',
    },
    {
        id: 'it-IT',
        serverLocale: 'it-IT',
        name: 'LANGUAGE.ITALY',
        imgSrc: 'assets/images/country/italy.svg',
    },
    {
        id: 'nl',
        serverLocale: 'nl-NL',
        name: 'LANGUAGE.NETHERLANDS',
        imgSrc: 'assets/images/country/netherlands.svg',
    },
    {
        id: 'pt',
        serverLocale: 'pt-PT',
        name: 'LANGUAGE.PORTUGAL',
        imgSrc: 'assets/images/country/portugal.svg',
    },
    {
        id: 'en-IE',
        serverLocale: 'en-IE',
        name: 'LANGUAGE.IRELAND',
        imgSrc: 'assets/images/country/ireland.svg',
    },
    {
        id: 'es',
        serverLocale: 'es-ES',
        name: 'LANGUAGE.SPAIN',
        imgSrc: 'assets/images/country/spain.svg',
    },
    {
        id: 'sv',
        serverLocale: 'sv-SE',
        name: 'LANGUAGE.SWEDEN',
        imgSrc: 'assets/images/country/sweden.svg',
    },
    {
        id: 'de-CH',
        serverLocale: 'de-CH',
        name: 'LANGUAGE.SWITZERLAND',
        imgSrc: 'assets/images/country/switzerland.svg',
    },
    {
        id: 'en-GB',
        languageCode: 'en_GB',
        serverLocale: 'en-GB',
        name: 'LANGUAGE.UK',
        imgSrc: 'assets/images/country/uk.svg',
    },
    {
        id: 'en-US',
        languageCode: 'en',
        serverLocale: 'en-US',
        name: 'LANGUAGE.USA',
        imgSrc: 'assets/images/country/usa.svg',
    },
]

const DISPLAY_NAME: Record<string, string> = {
    'LANGUAGE.USA': 'LANGUAGE.ENGLISH-US',
    'LANGUAGE.UK': 'LANGUAGE.ENGLISH-UK',
    'LANGUAGE.GERMANY': 'LANGUAGE.GERMAN',
}

// LANGUAGE_LIST - is the list of all supported legal document languages
// We don't support app localization for all of the LANGUAGE_LIST,
// but only for those which have `languageCode`
// In future if you require to add new locale support, just add `languageCode` to LANGUAGE_LIST
export const APP_LANGUAGE_LIST = LANGUAGE_LIST.filter((x) => x.languageCode).map((x) => ({
    ...x,
    name: DISPLAY_NAME[x.name] ?? x.name, // We had requirement to display different language name in settings
}))

export default LANGUAGE_LIST
