<div class="container d-flex align-items-center h-100">
    <div class="d-flex flex-grow">
        <img src="assets/images/golfer.svg" alt="404" />
        <div class="details flex-grow">
            <div class="d-flex justify-content-between">
                <div>
                    <h1 class="title">{{ 'ERROR_PAGE.HEADER' | translate }}</h1>
                    <h2 class="text-lg">{{ title | translate }}</h2>
                </div>
                <img src="assets/images/academyLogo.svg" alt="academy" />
            </div>
            <p [innerHTML]="description | translate" class="text-md"></p>
        </div>
    </div>
</div>
