import { Injectable } from '@angular/core'
import { Ibd, Configuration, ConfigurationDataReturnType } from '@ct-ibd/cmn-ibd-typescript-sdk'
import { environment } from 'env'
import { StorageService } from '@app/core/services/storage/storage.service'

const config = new Configuration(environment.microservices.cmn.appClientId, environment.microservices.cmn.name).load()

/**
 * Dont use this class directly. Use a shared instance of CmnSdkService instead
 * For authentication purposes only
 *
 **/
@Injectable({
    providedIn: 'root',
})
export class _CmnSdkWrapperService extends Ibd {
    protected idbConfig: ConfigurationDataReturnType

    constructor(idbState: StorageService) {
        super(idbState as any, config)
        this.idbConfig = config
    }
}
