import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
    @Input() title: string
    @Input() description: string
}
