import { NgModule, APP_INITIALIZER } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found.component'
import { ToastModule } from '@lib/toast'
import { AngularSvgIconModule } from 'angular-svg-icon'
import { firstValueFrom } from 'rxjs'
import { ErrorPageModule } from '@shared/components/error-page/error-page.module'
import { AnalyticsModule } from './shared/analytics/analytics.module'
import { provideQuillConfig } from 'ngx-quill'
import { AppTranslateLoaderService } from './core/services/app-translate/app-translate-loader.service'

function translateInitFactory(translate: TranslateService) {
    return () => {
        translate.setDefaultLang('en-US')
        return firstValueFrom(translate.use('en-US'))
    }
}

@NgModule({
    declarations: [AppComponent, PageNotFoundComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en-US',
            loader: {
                provide: TranslateLoader,
                useClass: AppTranslateLoaderService,
                deps: [HttpClient],
            },
        }),
        ToastModule.forRoot(),
        AngularSvgIconModule.forRoot(),
        ErrorPageModule,
        AnalyticsModule,
    ],
    providers: [
        [
            {
                provide: APP_INITIALIZER,
                deps: [TranslateService],
                multi: true,
                useFactory: translateInitFactory,
            },
        ],
        provideHttpClient(withInterceptorsFromDi()),
        provideQuillConfig({
            modules: {
                // syntax: true,
                toolbar: [[{ header: [1, 2, 3, false] }], ['bold', 'italic'], [{ list: 'bullet' }], [{ indent: '-1' }, { indent: '+1' }]],
            },
        }),
    ],
})
export class AppModule {}
