import { Inject, Injectable, Optional, Renderer2, RendererFactory2 } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { IndividualConfig, ToastrService, ActiveToast } from 'ngx-toastr'
import { ToastOptions } from '../interfaces/toast-options.interface'
import { ToastServiceInterface } from '../interfaces/toast-service.interface'
import { Toast } from '../interfaces/toast.interface'
import { DefaultToastConfig, TOAST_CONFIG } from '../toast.config'
import { AuthService } from '@app/core/services/auth/auth.service'
import { debounceTime } from 'rxjs'

export const CUSTOM_TOAST_STYLE = {
    NEW_ASSESSMENT: 'toast-new-assessment',
    NEW_CONNECTION: 'toast-new-connection',
}

@Injectable({
    providedIn: 'any',
})
export class ToastService implements ToastServiceInterface {
    private renderer: Renderer2

    constructor(
        private toastrService: ToastrService,
        private translateService: TranslateService,
        @Optional() @Inject(TOAST_CONFIG) private toastConfig: ToastOptions,
        private authService: AuthService,
        private rendererFactory: RendererFactory2
    ) {
        this.renderer = rendererFactory.createRenderer(null, null)

        this.authService.logged.pipe(debounceTime(50)).subscribe((status) => {
            if (status === false) {
                this.renderer.removeClass(document.body, 'logged-in')
            } else {
                this.renderer.addClass(document.body, 'logged-in')
            }
        })
    }

    close(toastId: number) {
        this.toastrService.remove(toastId)
    }

    closeAll(): void {
        this.toastrService.clear()
    }

    error(subtitle: string, _title?: string, options: Partial<ToastOptions> = {}): Toast {
        const config = this.makeConfig(options)
        const title = this.makeTitle('error', _title)
        const toast = this.toastrService.error(subtitle, title, config)
        return this.makeToast(toast)
    }

    info(subtitle: string, _title?: string, options: Partial<ToastOptions> = {}): Toast {
        const config = this.makeConfig(options)
        const title = this.makeTitle('info', _title)
        const toast = this.toastrService.info(subtitle, title, config)
        return this.makeToast(toast)
    }

    success(subtitle: string, _title?: string, options: Partial<ToastOptions> = {}): Toast {
        const config = this.makeConfig(options)
        const title = this.makeTitle('success', _title)
        const toast = this.toastrService.success(subtitle, title, config)
        return this.makeToast(toast)
    }

    warning(subtitle: string, _title?: string, options: Partial<ToastOptions> = {}): Toast {
        const config = this.makeConfig(options)
        const title = this.makeTitle('warning', _title)
        const toast = this.toastrService.warning(subtitle, title, config)
        return this.makeToast(toast)
    }

    show(title: string, subtitle: string, styleClass: string): Toast {
        const config = this.makeConfig({
            toastClass: `toast ${styleClass}`,
        })
        const toast = this.toastrService.show(subtitle, title, config)
        return this.makeToast(toast)
    }

    private makeConfig(options: Partial<ToastOptions>): Partial<IndividualConfig> {
        const config = {
            ...DefaultToastConfig,
            ...this.toastConfig,
            ...options,
        }
        // Istanbul treats all these lines as branches. Its not really a branch, just a way to map all properties as optional.
        // We dont want to test each branch, whatever properties are provided, we just pass them
        // istanbul ignore next
        const x = {
            ...(config.time && { timeOut: config?.time }),
            ...(config.easeTime && { easeTime: config?.easeTime }),
            ...(config.tapToDismiss && { tapToDismiss: config?.tapToDismiss }),
            ...(config.position && { positionClass: 'toast-' + config.position }),
            ...(config.onActivateTick && { onActivateTick: config.onActivateTick }),
            ...(config.toastClass && { toastClass: config.toastClass }),
            closeButton: true,
        }

        return x
    }
    private makeTitle(source: 'error' | 'info' | 'warning' | 'success', x?: string): string {
        if (x) {
            return x
        }
        const path = 'GENERAL.' + source.toUpperCase()
        const translation = this.translateService.instant(path)
        if (translation === path) {
            console.warn(`Translation for: ${path} was not loaded`)
            return source
        }
        return translation
    }

    private makeToast(toast: ActiveToast<any>): Toast {
        return {
            toastId: toast.toastId,
            title: toast.title,
            message: toast.message,
            onShow: toast.onShown,
            onDismiss: toast.onHidden,
            onTap: toast.onTap,
        }
    }
}
